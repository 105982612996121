import React from 'react';
import Footer from './components/footer';
import Tiles from './components/tiles';

function App() {
  return (
    <div className={"d-flex flex-column"} style={{ minHeight: "100vh" }}>
      <div className={"flex-grow-1"}>
        <Tiles />
      </div>
      <Footer />
    </div>
  );
}

export default App;
