import React from 'react';
import { Button, Col, Container, Jumbotron, Row } from 'react-bootstrap';

const Tiles = () => {
  return (
    <Container className={'mb-3'}>
      <h1 className={'my-0 py-4'}>Design Transformation</h1>
      <Row>
        <Col sm={12} md={6} lg={4} xl={3}>
          <Jumbotron className={'bg-light py-4'}>
            <h5>Cross Sell</h5>
            <p>Use your document defined by URL and transform design to selected product with specified options.</p>
            <img src={'https://api.netlify.com/api/v1/badges/4b5df43b-161f-444b-8b74-6f3a46627ce1/deploy-status'} alt={'netlify status'}/>
            <Button className={'mt-4'} block href={'https://cross-sell.design-transformation.vp.duchon.eu/'}>Transform document</Button>
          </Jumbotron>
        </Col>
        <Col sm={12} md={6} lg={4} xl={3}>
          <Jumbotron className={'bg-light py-4'}>
            <h5>Realization</h5>
            <p>Using realization tools you can preview transformation instructions, analyze preview URL and compare realization results in different
              environments.</p>
            <Button block href={'https://ui.designtransformation.vpsvc.com/transformation-realization'}>Try it!</Button>
          </Jumbotron>
        </Col>
      </Row>
    </Container>
  );
};

export default Tiles;
