import { faSlack } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className={'py-3 bg-dark'}>
      <Container>
        <Row>
          <Col sm={12} md={6} lg={4} xl={3}>
            <h5>More about...</h5>
            <ul>
              <li>
                <a href={'https://vistaprint.atlassian.net/wiki/spaces/Content/pages/20119634/Design+transformation'} target="_blank" rel="noopener noreferrer">Design
                  Transformation</a>
              </li>
              <li>
                <a href={'https://vistaprint.atlassian.net/wiki/spaces/MT/pages/44990664/Cross-sell+Page'} target="_blank" rel="noopener noreferrer">Cross
                  Sell</a>
              </li>
              <li>
                <a href={'https://vistaprint.atlassian.net/wiki/spaces/Content/pages/36831515/Design+Transformation+Realization'} target="_blank"
                   rel="noopener noreferrer">Realization</a>
              </li>
              <li>
                <a href={'https://www.netlify.com'} target="_blank" rel="noopener noreferrer">netlify</a>
              </li>
            </ul>
          </Col>
          <Col sm={12} md={6} lg={8} xl={9}>
            <h5>Questions?</h5>
            <ul>
              <li>
                <h6>Mercury Squad</h6>
                <div>
                  <a title={'Send e-mail'} href={'mailto:mercurysquaddev@vistaprint.com'}>
                    <FontAwesomeIcon icon={faEnvelope} fixedWidth={true}/> MercurySquadDev@vistaprint.com
                  </a>
                </div>
                <div>
                  <a title={'Slack us'} href={'https://vistaprint.slack.com/archives/CDVP74ABC'}>
                    <FontAwesomeIcon icon={faSlack} fixedWidth={true}/> squad-mercury
                  </a>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <h6>Radek Duchon</h6>
                <div>
                  <a title={'Send e-mail'} href={'mailto:radek.duchon@vistaprint.com'}>
                    <FontAwesomeIcon icon={faEnvelope} fixedWidth={true}/> radek.duchon@vistaprint.com
                  </a>
                </div>
                <div>
                  <a title={'Slack me'} href={'https://vistaprint.slack.com/archives/DB68J7T8F'}>
                    <FontAwesomeIcon icon={faSlack} fixedWidth={true}/> radek
                  </a>
                </div>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
